import React, { Fragment, useState } from 'react';
import LobbyService from '../../bl/LobbyService';
import { Link, useHistory } from 'react-router-dom';
import { Button, Divider, Form, Icon, Input } from 'semantic-ui-react';
import NicknameFormField from './NicknameFormField';

function JoinLobby(props) {
  let [nickname, setNickname] = useState(null);
  let [joingCode, setJoinCode] = useState();
  const history = useHistory();

  const onJoinCodeChanged = (event) => {
    setJoinCode(event.target.value);
  }

  const onJoinClicked = async () => {
    const [player, lobby] = await new LobbyService().joinLobby(joingCode, nickname);
    props.setPlayer(player);
    props.setLobby(lobby);
    history.push('/lobby');
  }

  return (
    <Fragment>
      <h2>Join a Quiz</h2>

      <Form>
        <NicknameFormField setName={setNickname} />
        <Form.Field width={8} >
          <label>Lobby code</label>
          <Input onChange={onJoinCodeChanged} placeholder="Code..." />
        </Form.Field>
        <Button onClick={(onJoinClicked)} animated>
          <Button.Content visible>Continue</Button.Content>
          <Button.Content hidden>
            <Icon name='arrow right' />
          </Button.Content>
        </Button>
      </Form>
      <Divider />
      <Link to='/new'>or start a lobby</Link>
    </Fragment>
  );
}

export default JoinLobby;