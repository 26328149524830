import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react';

function Home({ mobile }) {

    return (
        <Segment textAlign='center' vertical>
            <Container text>
                <Header
                    as='h1'
                    content='Welcome to Quizly!'
                    style={{
                        fontSize: mobile ? '2em' : '3em',
                        marginTop: '1em',
                    }}
                />
                <Header
                    as='h2'
                    content='3700+ verified questions from 23 categories...'
                    style={{
                        fontSize: mobile ? '1.5em' : '1.7em',
                        fontWeight: 'normal',
                        marginBottom: mobile ? '0.5em' : '1.em',
                    }}
                />
                <Segment placeholder>
                    <Grid columns={2} textAlign='center'>
                        <Divider vertical>Or</Divider>

                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Header icon>
                                    <Icon name='users' /> Join existing lobby  </Header>
                                <Link to='/join' >
                                    <Button primary >Join</Button>
                                </Link>
                            </Grid.Column>

                            <Grid.Column>
                                <Header icon>
                                    <Icon name='add' /> Create new lobby </Header>
                                <Link to='/new' >
                                    <Button primary>Create</Button>
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        </Segment>
    );
}

export default Home;
