import API_ROOT from "./API_ROOT";

class StompMessagingClient {
    static instance = null;
    stompClient = null;

    constructor() {
        var socket = new window.SockJS(API_ROOT + 'gs-guide-websocket');
        this.stompClient = window.Stomp.over(socket);
    }

    static getInstance() {
        if (this.instance == null) {
            this.instance = new StompMessagingClient();
        }
        return this.instance;
    }

    connect() {
        return new Promise((resolve, reject) => {
            this.stompClient.connect({}, function (frame) {
                console.log('Connected: ' + frame);
                resolve();
            }, reject);
        });
    }

    subscribe(path, onMessageReceived) {
        this.stompClient.subscribe(path, (message) => {
            onMessageReceived(JSON.parse(message.body));
        });
    }
}

export default StompMessagingClient;