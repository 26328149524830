export default [
    {
        key: "any",
        value: "any",
        text: "Mixed (random)"
    }, {
        key: "9",
        value: "9",
        text: "General Knowledge"
    }, {
        key: "10",
        value: "10",
        text: "Entertainment: Books"
    }, {
        key: "11",
        value: "11",
        text: "Entertainment: Film"
    }, {
        key: "12",
        value: "12",
        text: "Entertainment: Music"
    }, {
        key: "13",
        value: "13",
        text: "Entertainment: Musicals & Theatres"
    }, {
        key: "14",
        value: "14",
        text: "Entertainment: Television"
    }, {
        key: "15",
        value: "15",
        text: "Entertainment: Video Games"
    }, {
        key: "16",
        value: "16",
        text: "Entertainment: Board Games"
    }, {
        key: "17",
        value: "17",
        text: "Science &amp; Nature"
    }, {
        key: "18",
        value: "18",
        text: "Science: Computers"
    }, {
        key: "19",
        value: "19",
        text: "Science: Mathematics"
    }, {
        key: "20",
        value: "20",
        text: "Mythology"
    }, {
        key: "21",
        value: "21",
        text: "Sports"
    }, {
        key: "22",
        value: "22",
        text: "Geography"
    }, {
        key: "23",
        value: "23",
        text: "History"
    }, {
        key: "24",
        value: "24",
        text: "Politics"
    }, {
        key: "25",
        value: "25",
        text: "Art"
    }, {
        key: "26",
        value: "26",
        text: "Celebrities"
    }, {
        key: "27",
        value: "27",
        text: "Animals"
    }, {
        key: "28",
        value: "28",
        text: "Vehicles"
    }, {
        key: "29",
        value: "29",
        text: "Entertainment: Comics"
    }, {
        key: "30",
        value: "30",
        text: "Science: Gadgets"
    }, {
        key: "31",
        value: "31",
        text: "Entertainment: Japanese Anime & Manga"
    }, {
        key: "32",
        value: "32",
        text: "Entertainment: Cartoon & Animations"
    }];
