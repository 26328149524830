import ordinal from 'ordinal';
import React, { Fragment, useContext } from 'react';
import Confetti from 'react-confetti';
import { Link } from 'react-router-dom';
import { Divider, Label, Table } from 'semantic-ui-react';
import { UserContext } from '../../context/UserContext';

function Scoreboard(props) {
  const player = useContext(UserContext);

  return (
    <Fragment>
      <h2>Quiz Complete - Scoreboard</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Player</Table.HeaderCell>
            <Table.HeaderCell>Score</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.players.sort((p1, p2) => p2.score - p1.score).map((p, idx) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Label ribbon>{ordinal(idx + 1)}</Label>
                  {player.id === p.id ? '(me) ' : ''}{p.name}</Table.Cell>
                <Table.Cell>{p.score}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Divider />
      <Link to='/lobby' style={{ textAlign: 'center' }}>Back to the lobby</Link>
      <Confetti />
    </Fragment>
  );
}

export default Scoreboard;