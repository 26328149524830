import React, { Fragment, useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import LobbyService from '../../bl/LobbyService';
import { Button, Dimmer, Divider, Grid, Header, Image, List, Loader, Popup, Segment } from 'semantic-ui-react';
import QuizOptions from './QuizOptions';
import ReadOnlyQuizOptions from './ReadOnlyQuizOptions';
import './lobby.css';

function Lobby(props) {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(props.lobby.settings);

  const updateSettings = (settings) => {
    setSettings(settings);
    new LobbyService().updateSettings(props.lobby.id, settings.questionCount, settings.category, settings.difficulty, settings.questionDurationSeconds);
  }

  const onStartQuizClicked = async () => {
    setLoading(true);
    const response = await new LobbyService().startQuiz(props.lobby.id, settings.questionCount, settings.category, settings.difficulty, settings.questionDurationSeconds);
    setLoading(false);
    if(response === 404) {
      window.alert("sorry, there aren't enough questions that match your criteria. please adjust the difficulty, category or number of questions and try again.");
    }
  };

  return <Fragment>
    <h2>Lobby</h2>
    <Divider hidden />

    <Grid>
      <Grid.Column width='6'>
        <h3>Code</h3>
        <LobbyCode code={props.lobby.id} />
      </Grid.Column>
      <Grid.Column width={10}>
        <h3>Players ({props.lobby.players.length})</h3>
        <Segment style={{}}>
          <PlayersList players={props.lobby.players} />
        </Segment>
      </Grid.Column>
    </Grid>

    <Divider hidden />

    <Header as='h3'>
      Settings
      <Header.Subheader>
        Settings are controlled by the Quiz Master.
      </Header.Subheader>
    </Header>
    {(() => {
      if (props.isQuizMaster) {
        return <QuizOptions settings={settings} setSettings={updateSettings} />
      } else {
        return <ReadOnlyQuizOptions settings={props.lobby.settings} />
      }
    })()}
    <div style={{margin: '2em 0'}}>
      <Divider />
    </div>

    {(() => {
      if (props.isQuizMaster) {
        return <Button primary onClick={onStartQuizClicked} size='huge'>Start Quiz</Button>;
      } else {
        return (
          <Fragment>
            <Loader active inline size='small' className='workaround' style={{ marginRight: '1em' }} />
            &nbsp;Waiting for the Quiz Master to start the quiz...
          </Fragment>
        );
      }
    })()}

    {loading && <Dimmer active><Loader active /></Dimmer>}
  </Fragment>;
}

function LobbyCode(props) {
  const [isCopiedTooltipOpen, setIsCopiedTooltipOpen] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState(null);

  const copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = props.code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const openCopiedTooltip = () => {
    setIsCopiedTooltipOpen(true);
    let timeout = setTimeout(() => setIsCopiedTooltipOpen(false), 2000);
    setTooltipTimeout(timeout);
  };

  const closeCopiedTooltip = () => {
    setIsCopiedTooltipOpen(false);
    clearTimeout(tooltipTimeout);
  };

  return <Fragment>
    <Popup trigger={<Button content='Copy' id='lobby-code' icon='copy' label={{
      as: 'a',
      basic: true,
      pointing: 'right',
      content: props.code
    }} labelPosition='left' onClick={copyToClipboard} />} content='Copied to clipboard' on='click' open={isCopiedTooltipOpen} onClose={closeCopiedTooltip} onOpen={openCopiedTooltip} position='right center' />
  </Fragment>;
}

function PlayersList(props) {
  const player = useContext(UserContext);
  return <Fragment>
    <List verticalAlign='middle'>
      {props.players.map((p, idx) => {
        console.log(p)
        let suffix = p.id === player.id ? ' (me)' : '';
        suffix += p.quizMaster ? ' (quiz master)' : '';

        return <List.Item key={p.id}>
          <Image avatar src='https://react.semantic-ui.com/images/avatar/small/helen.jpg' />
          <List.Content> <List.Header>{p.name + suffix}</List.Header> </List.Content>
        </List.Item>;
      })}
    </List>
  </Fragment>;
}

export default Lobby;