import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { Route, Switch, Redirect, useHistory, withRouter } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import JoinLobby from './lobby/JoinLobby';
import NewLobby from './lobby/NewLobby';
import Lobby from './lobby/Lobby';
import LobbyService from '../bl/LobbyService';
import StompMessagingClient from '../bl/StompMessagingClient';
import Question from './quiz/Question';
import Scoreboard from './quiz/Scoreboard';
import QuestionResults from './quiz/QuestionResults';
import Home from './home/Home';
import Header from './layout/Header';
import { Container } from 'semantic-ui-react';

function App(props) {
  let [player, setPlayer] = useState(null);
  let [lobby, setLobby] = useState(null);
  // secondsRemaining is used to store seconds remaining for any countdown in the lobby
  let [secondsRemaining, setSecondsRemaining] = useState(null);
  let [loading, setLoading] = useState(true);
  let [failedToConnect, setFailedToConnect] = useState(false);
  const history = useHistory();
  const previousLobbyStatus = usePrevious(lobby?.status);


  const setAndSubscribeToLobby = (lobby) => {
    setLobby(lobby);
    new LobbyService().subscribeToLobby(lobby.id, (lobby) => {
      setLobby(lobby);
    });

    new LobbyService().subscribeToCountdown(lobby.id, (timer) => {
      setSecondsRemaining(timer.secondsRemaining);
    });
  }

  useEffect(() => {
    StompMessagingClient.getInstance().connect().then(() => setLoading(false)).catch(() => setFailedToConnect(true));
  }, []);

  useEffect(() => {
    // go to /play route if the status updates and we are not alrady there
    if (lobby?.status && previousLobbyStatus !== lobby?.status && props.location.pathname !== '/play') {
      history.push('/play');
    }
  }, [lobby, previousLobbyStatus, props.location.pathname, history]);


  if (failedToConnect) {
    return <p>please check your connection...</p>;
  } else if (loading) {
    return <p>loading...</p>;
  }

  return (
    <UserContext.Provider value={player}>
        <Header />
        <Container text>
          <div className="App">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/new">
                <NewLobby setPlayer={setPlayer} setLobby={setAndSubscribeToLobby} />
              </Route>
              <Route path="/join">
                <JoinLobby setPlayer={setPlayer} setLobby={setAndSubscribeToLobby} />
              </Route>
              <Route path="/play">
                {(() => {
                  if (!(player && lobby)) {
                    return <Redirect to='/' />;
                  }
                  
                  if (lobby.status === 'playing') {
                    return <Question lobbyId={lobby.id} question={lobby.questions[lobby.indexOfCurrentQuestion]} secondsRemaining={secondsRemaining} questionDurationSeconds={lobby.settings.questionDurationSeconds} indexOfCurrentQuestion={lobby.indexOfCurrentQuestion} totalNumberOfQuestions={lobby.questions.length} />
                  } else if (lobby.status === 'starting') {
                    return <h1>Starting in {secondsRemaining} second...</h1>;
                  } else if (lobby.status === 'finished') {
                    return <Scoreboard players={lobby.players} />
                  } else if (lobby.status === 'question_results') {
                    return <QuestionResults lobby={lobby} secondsRemaining={secondsRemaining} isLastQuestion={lobby.questions.length === lobby.indexOfCurrentQuestion + 1} />
                  } else {
                    return <Redirect to='/error/unknown_lobby_state' />
                  }
                })()}
              </Route>
              <Route>
                {/* Default route */}
                {(() => {
                  if (!(player && lobby)) {
                    return <Redirect to='/' />;
                  }
                  return <Lobby lobby={lobby} isQuizMaster={player.quizMaster} />;
                })()}
              </Route>
            </Switch>
          </div>
        </Container>
    </UserContext.Provider >
  );
}

// Custom hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export default withRouter(App);
