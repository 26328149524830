import ordinal from 'ordinal';
import React, { Fragment, useContext } from 'react';
import { Grid, Header, Icon, Label, Table } from 'semantic-ui-react';
import { UserContext } from '../../context/UserContext';

function QuestionResults(props) {
  const player = useContext(UserContext);
  const questionResult = props.lobby.playerIdToCurrentQuestionResult[player.id];
  const answeredCorrectly = questionResult && questionResult.answeredCorrectly;
  const correctAnswer = props.lobby.questions[props.lobby.indexOfCurrentQuestion].answers.find(a => a.correct);

  return (
    <Grid style={{ paddingTop: '1em' }} columns={1} textAlign='center'>
      <Grid.Row verticalAlign='middle' textAlign='center'>
        <Grid.Column>
          <Header size='huge' icon>
            <Icon name={answeredCorrectly ? 'check' : 'ban'} color={answeredCorrectly ? 'green' : 'red'} />
            {answeredCorrectly ? 'Correct' : 'Wrong'}
          </Header>
          <Header.Subheader >
            {!answeredCorrectly && ( <Fragment> The correct answer was: <span dangerouslySetInnerHTML={{ __html: correctAnswer.text }} /> </Fragment>) }
          </Header.Subheader>
          <Table celled collapsing style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Position</Table.HeaderCell>
                <Table.HeaderCell>Player</Table.HeaderCell>
                <Table.HeaderCell>Answer</Table.HeaderCell>
                <Table.HeaderCell>Score</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.lobby.players.sort((p1, p2) => p2.score - p1.score).map((p, idx) => {
                const result = props.lobby.playerIdToCurrentQuestionResult[p.id];
                const playerAnsweredCorrectly = result && result.answeredCorrectly;
                return (
                  <Table.Row>
                    <Table.Cell>
                      {player.id === p.id && <Label ribbon>me</Label>}
                      {ordinal(idx + 1)}
                    </Table.Cell>
                    <Table.Cell>{p.name}</Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Icon name={playerAnsweredCorrectly ? 'check' : 'ban'} color={playerAnsweredCorrectly ? 'green' : 'red'} />
                    </Table.Cell>
                    <Table.Cell>{p.score}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {props.isLastQuestion ? '' : <Fragment>next question in <b>{props.secondsRemaining}</b> seconds...</Fragment>}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default QuestionResults;