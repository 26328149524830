import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Divider, Form, Icon } from 'semantic-ui-react';
import LobbyService from '../../bl/LobbyService';
import NicknameFormField from './NicknameFormField';

function NewLobby(props) {
  const [nickname, setNickname] = useState(null);
  const history = useHistory();

  const onCreateClicked = async (event) => {
    const [player, lobby] = await new LobbyService().createLobby(nickname);
    props.setPlayer(player);
    props.setLobby(lobby);
    history.push('/lobby');
  };

  return (
    <Fragment>
      <h2>Start a new Quiz</h2>
      <Form>
        <NicknameFormField setName={setNickname} />
        <Button onClick={(onCreateClicked)} animated>
          <Button.Content visible>Continue</Button.Content>
          <Button.Content hidden>
            <Icon name='arrow right' />
          </Button.Content>
        </Button>
      </Form>
      <Divider />
      <Link to='/join' style={{ textAlign: 'center' }}>or join an existing lobby</Link>
    </Fragment>
  );
}

export default NewLobby;