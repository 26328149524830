import React from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import LobbyService from '../../bl/LobbyService';
import QUESTION_CATEGORY_OPTIONS from './QUESTION_CATEGORY_OPTIONS';

export default function ReadOnlyQuizOptions(props) {
  return (
    <Table basic='very' celled unstackable>
      <Table.Header>
        <Table.Row >
          <Table.HeaderCell>Number of questions</Table.HeaderCell>
          <Table.HeaderCell>Difficulty</Table.HeaderCell>
          <Table.HeaderCell>Category
            <Popup
              hoverable
              content={<AllCategoriesList />}
              header='All categories'
              trigger={<Button style={{ marginLeft: '1em' }} circular compact icon='info' />} />
          </Table.HeaderCell>
          <Table.HeaderCell>Time per question</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell>{props.settings.questionCount}</Table.Cell>
          <Table.Cell>{props.settings.difficulty}</Table.Cell>
          {/* eslint-disable-next-line */}
          <Table.Cell>{QUESTION_CATEGORY_OPTIONS.find(o => o.value == (props.settings.category || 'any')).text}</Table.Cell>
          <Table.Cell>{LobbyService.formatSeconds(props.settings.questionDurationSeconds)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

function AllCategoriesList() {
  return (
    <ul>
      {QUESTION_CATEGORY_OPTIONS.map(category => {
        return <li dangerouslySetInnerHTML={{ __html: category.text }} />;
      })}
    </ul>
  )
}