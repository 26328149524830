import React, { Fragment, useState, useContext } from 'react';
import { Grid, Header, Progress, Divider } from 'semantic-ui-react';
import LobbyService from '../../bl/LobbyService';
import { UserContext } from '../../context/UserContext';
import { randomColor } from 'randomcolor';

function Question(props) {
  const question = props.question;
  const player = useContext(UserContext);
  const [chosenAnswerId, setChosenAnswerId] = useState(null);
  const [randomColours] = useState([randomColor(), randomColor(), randomColor(), randomColor()]);

  const recordAnswer = async (answerId) => {
    if (chosenAnswerId) return; // already answered
    await new LobbyService().recordAnswer(props.lobbyId, player.id, answerId);
    setChosenAnswerId(answerId);
  }

  let progressLabel = props.secondsRemaining + ' seconds left';
  if (chosenAnswerId) {
    progressLabel = 'waiting for other players - ' + progressLabel;
  }

  return (
    <Fragment>
      <Header>
        <h2 dangerouslySetInnerHTML={{
          __html: question.text
        }} />
        <Header.Subheader>
          Quetion {props.indexOfCurrentQuestion + 1} of {props.totalNumberOfQuestions}
        </Header.Subheader>
        <Header.Subheader>
          {question.category}
        </Header.Subheader>
        <Header.Subheader>
          {props.question.points} points
        </Header.Subheader>
      </Header>
      <Grid>
        <Grid.Row columns={2}>
          {question.answers.map((a, idx) => {
            return <Grid.Column>
              <div onClick={() => recordAnswer(a.id)} style={{ height: '10em', border: '5px solid ' + randomColours[idx], borderRadius: '5px', textAlign: 'center', margin: '1em', padding: '1em', display: 'grid', cursor: chosenAnswerId ? '' : 'pointer', opacity: (chosenAnswerId && chosenAnswerId !== a.id ? '10%' : '100%') }} >
                <div style={{ margin: 'auto 0' }} dangerouslySetInnerHTML={{ __html: a.text }} />
              </div>
            </Grid.Column>;
          })}
        </Grid.Row>
      </Grid>
      <Divider hidden/>
      <Progress total={props.questionDurationSeconds} value={props.secondsRemaining} label={progressLabel} indicating />

    </Fragment>
  );
}


export default Question;