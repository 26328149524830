import React from 'react';
import { Menu, Image } from 'semantic-ui-react';

function Header(props) {
    return (
        <Menu size='huge'>
            <Menu.Item name='quizly' href='/' >
                <Image src='/favicon.png' size='mini' />
                &nbsp;&nbsp;&nbsp;&nbsp;Quizly
            </Menu.Item>
        </Menu>
    );
}

export default Header;