import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { adjectives, animals, uniqueNamesGenerator } from 'unique-names-generator';


function NicknameFormField(props) {
    const generateRandomName = () => uniqueNamesGenerator({ dictionaries: [adjectives, animals] }).replace('_', ' ');
    const [name, setName] = useState(generateRandomName());

    const onNameChanged = (event) => {
        setName(event.target.value);
        props.setName(event.target.value);
    }

    useEffect(() => props.setName(name), [name, props]);

    return (
        <Form.Field width={8} >
            <label>Nickname</label>
            <Input onChange={onNameChanged} placeholder="You're name..." value={name} action>
                <input />
                <Button icon='redo' onClick={() => setName(generateRandomName())} content='Random' basic color='blue'/>
            </Input>
        </Form.Field>
    );

}

export default NicknameFormField;