import React from 'react';
import { Grid, Label, Select } from 'semantic-ui-react';
import { Slider } from "react-semantic-ui-range";
import LobbyService from '../../bl/LobbyService';
import QUESTION_CATEGORY_OPTIONS from './QUESTION_CATEGORY_OPTIONS';

export default function QuizOptions(props) {
  const questionCountSliderSettings = {
    min: 5,
    max: 20,
    step: 1,
    onChange: (value) => {
      props.settings.questionCount = parseInt(value);
      props.setSettings(props.settings);
    }
  };
  const difficultySliderSettings = {
    min: 1,
    max: 3,
    step: 1,
    onChange: (value) => {
      props.settings.difficulty = LobbyService.difficultyToString(parseInt(value));
      props.setSettings(props.settings);
    }
  };
  const questionDurationSeconds = {
    min: 10,
    max: 120,
    step: 5,
    onChange: (value) => {
      props.settings.questionDurationSeconds = parseInt(value);
      props.setSettings(props.settings)
    }
  };

  console.log(props)

  return <Grid>
    <Grid.Row>
      <Grid.Column>
        <h4>Number of questions</h4>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={13}>
        <Slider value={props.settings.questionCount} color="blue" settings={questionCountSliderSettings} />
      </Grid.Column>
      <Grid.Column width={3}>
        <Label color="blue">{props.settings.questionCount}</Label>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <h4>Difficulty</h4>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={13}>
        <Slider value={LobbyService.difficultyToNumber(props.settings.difficulty)} color="blue" settings={difficultySliderSettings} />
      </Grid.Column>
      <Grid.Column width={3}>
        <Label color="blue">{props.settings.difficulty}</Label>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <h4>Category</h4>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Select fluid defaultValue='any' options={QUESTION_CATEGORY_OPTIONS} value={props.settings.questionCategory} onChange={(evt, data) => {
          props.settings.category = data.value;
          props.setSettings(props.settings)
        }} />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <h4>Time per question</h4>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={13}>
        <Slider value={props.settings.questionDurationSeconds} color="blue" settings={questionDurationSeconds} />
      </Grid.Column>
      <Grid.Column width={3}>
        <Label color="blue">{LobbyService.formatSeconds(props.settings.questionDurationSeconds)}</Label>
      </Grid.Column>
    </Grid.Row>
  </Grid>;
}