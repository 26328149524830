import API_ROOT from './API_ROOT';
import StompMessagingClient from './StompMessagingClient';


class LobbyService {
    async createLobby(playerName) {
        const response = await fetch(API_ROOT + 'lobby', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'playerName=' + playerName // body data type must match "Content-Type" header
        });

        const body = await response.json();
        return [body.player, body.lobby];
    }

    async joinLobby(code, playerName) {
        const response = await fetch(API_ROOT + 'lobby/' + code + '/join', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'playerName=' + playerName // body data type must match "Content-Type" header
        });

        const body = await response.json();
        return [body.player, body.lobby];
    }

    subscribeToLobby(lobbyId, onChange) {
        StompMessagingClient.getInstance().subscribe('/topic/lobby/' + lobbyId, onChange);
    }

    subscribeToCountdown(timerId, onTick) {
        StompMessagingClient.getInstance().subscribe('/topic/countdown/' + timerId, onTick);
    }

    async updateSettings(lobbyId, count, category, difficulty, secondsPerQuestion) {
        const body= JSON.stringify({
            questionCount: count,
            difficulty: difficulty,
            category: category,
            questionDurationSeconds: secondsPerQuestion
        });
        await fetch(API_ROOT + 'lobby/' + lobbyId + '/settings', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body // body data type must match "Content-Type" header
        });
    }

    async startQuiz(lobbyId, count, category, difficulty, secondsPerQuestion) {
        const body = JSON.stringify({
            questionCount: count,
            difficulty: difficulty,
            category: category,
            questionDurationSeconds: secondsPerQuestion
        });
        const res = await fetch(API_ROOT + 'lobby/' + lobbyId + '/start', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body // body data type must match "Content-Type" header
        });
        return res.status;
    }

    async recordAnswer(lobbyId, playerId, answerId) {
        await fetch(API_ROOT + 'lobby/' + lobbyId + '/answer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'playerId=' + playerId + '&answerId=' + answerId // body data type must match "Content-Type" header
        });
    }

    static difficultyToString(difficultyNumber) {
        switch (difficultyNumber) {
            case 1:
                return 'easy';

            case 2:
                return 'medium';

            case 3:
                return 'hard';

            default:
                throw new Error('unknown difficulty', difficultyNumber);
        }
    };

    static difficultyToNumber(difficultyString) {
        switch (difficultyString) {
            case 'easy':
                return 1;

            case 'medium':
                return 2;

            case 'hard':
                return 3;

            default: 
                throw new Error('unknown difficulty', difficultyString);
        }
    };

    static formatSeconds (secondsToFormat) {
      const minutes = Math.floor(secondsToFormat / 60);
      const seconds = secondsToFormat - minutes * 60;
      let formatted = minutes ? minutes + ' minute' : '';
      formatted += minutes > 1 ? 's ' : ' ';
      formatted += seconds ? seconds + ' seconds' : '';
      return formatted;
    };
}

export default LobbyService;